<template>
    <div class="article-panel taskdata-panel" v-loading="loading">
        <div class="taskdata-panel-inner">
            <h3 class="g-title">填写合同签署信息</h3>
            <div class="article-bd">
                <!-- ===============================报名信息填写内容=================================  -->
                <div class="task-sign-box task-agreement-box">
                    <div class="t-content">
                        <el-form :model="contract" ref="contract">
                            <!---------个人/工作室雇主---------->
                            <template
                                v-if="userInfo.info.user_type==2 && userInfo.info.attestation_type==1"
                            >
                                <div class="item">
                                    <div class="title">委托方（雇主）</div>
                                    <el-form-item class="body">
                                        <span v-text="userInfo.attestation.name"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">身份证号码</div>
                                    <el-form-item class="body">
                                        <span v-text="userInfo.attestation.idcard"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_mobile"
                                        :rules="[{required: true, message: '请填写您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_mobile"
                                            placeholder="请填写您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请确认您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">地址</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_address"
                                        :rules="[{required: true, message: '请填写您的地址', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_address"
                                            placeholder="请填写您的地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">雇主向工程极客提供的技术资料及数据的具体约定</div>
                                    <el-form-item class="body" prop="remark">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 4, maxRows: 4}"
                                            v-model="contract.remark"
                                            placeholder="项目开始执行后，雇主向工程极客提供必要的工作条件和技术背景资料。你可以在这里对这些资料进行说明。这里填写的内容将进入合同。"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </template>

                            <!---------单位雇主---------->
                            <template
                                v-if="userInfo.info.user_type==2 && userInfo.info.attestation_type==2"
                            >
                                <div class="item">
                                    <div class="title">委托方（雇主）</div>
                                    <el-form-item class="body">
                                        <span v-text="contract.employer_name"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <el-form-item class="body" prop="employer_is_proxy">
                                        <el-radio-group
                                            v-model="contract.employer_is_proxy"
                                            @change="employerProxyChange"
                                            size="mini"
                                        >
                                            <el-radio-button label="0">我是法人</el-radio-button>
                                            <el-radio-button label="1">我是受委托签署人</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy!=1">
                                    <div class="title">单位法定代表人</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer"
                                        :rules="[{required: true, message: '请填写法人姓名', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer"
                                            placeholder="请填写法人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy!=1">
                                    <div class="title">法人身份证号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_idcard"
                                        :rules="[{required: true, message: '请填写法人身份证号码', trigger: ['change', 'blur']},{validator:this.validata.idCard(),trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_idcard"
                                            placeholder="请填写法人身份证号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy!=1">
                                    <div class="title">法人手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_mobile"
                                        :rules="[{required: true, message: '请填写法人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_mobile"
                                            placeholder="请填写法人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy!=1">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_mobile"
                                        :rules="[{required: true, message: '请确认法人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认法人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy==1">
                                    <div class="title">受托签署人姓名</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_proxy_name"
                                        :rules="[{required: true, message: '请填写受托签署人姓名', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_proxy_name"
                                            placeholder="请填写受托签署人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy==1">
                                    <div class="title">受托签署人身份证号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_proxy_idcard"
                                        :rules="[{required: true, message: '请填写受托签署人身份证号码', trigger: ['change', 'blur']},{validator:this.validata.idCard(),trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_proxy_idcard"
                                            placeholder="请填写受托签署人身份证号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy==1">
                                    <div class="title">受托签署人手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_proxy_mobile"
                                        :rules="[{required: true, message: '请填写受托签署人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_proxy_mobile"
                                            placeholder="请填写受托签署人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy==1">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_proxy_mobile"
                                        :rules="[{required: true, message: '请确认受托签署人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认受托签署人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.employer_is_proxy==1">
                                    <div class="title">如您是受托签署人，请下载下面的授权委托书，签字盖章后扫描或拍照上传</div>
                                    <div class="btns" style="margin-top:10px;">
                                        <el-form-item
                                            class="body"
                                            prop="employer_proxy_certificate"
                                            :rules="[{required: true, message: '请上传授权委托书', trigger: ['change', 'blur']}]"
                                        >
                                            <el-button
                                                @click="download_employer_auth_file"
                                                size="mini"
                                            >授权委托书模板下载</el-button>
                                            <only-upload
                                                @onupload="employer_upload"
                                                :before-upload="before_upload"
                                            >
                                                <el-button
                                                    slot="trigger"
                                                    size="mini"
                                                    v-text="upload_text"
                                                ></el-button>
                                            </only-upload>
                                            <el-image
                                                class="view_proxy_pic"
                                                v-if="contract.employer_proxy_certificate"
                                                :src="util.reImg(contract.employer_proxy_certificate)"
                                            ></el-image>
                                        </el-form-item>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="title">单位联系电话</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_org_phone"
                                        :rules="[{required: true, message: '请填写单位联系电话', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_org_phone"
                                            placeholder="请填写单位联系电话"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">单位地址</div>
                                    <el-form-item
                                        class="body"
                                        prop="employer_address"
                                        :rules="[{required: true, message: '请填写单位地址', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.employer_address"
                                            placeholder="请填写单位地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">雇主向工程极客提供的技术资料及数据的具体约定</div>
                                    <el-form-item class="body" prop="remark">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 4, maxRows: 4}"
                                            v-model="contract.remark"
                                            placeholder="项目开始执行后，雇主向工程极客提供必要的工作条件和技术背景资料。你可以在这里对这些资料进行说明。这里填写的内容将进入合同。"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </template>

                            <!---------个人/工作室工程极客---------->
                            <template
                                v-if="userInfo.info.user_type==1 && userInfo.info.attestation_type==1"
                            >
                                <div class="item">
                                    <div class="title">服务方（工程极客）</div>
                                    <el-form-item class="body">
                                        <span v-text="userInfo.attestation.name"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">身份证号码</div>
                                    <el-form-item class="body">
                                        <span v-text="userInfo.attestation.idcard"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请填写您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_mobile"
                                            placeholder="请填写您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请确认您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">地址</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_address"
                                        :rules="[{required: true, message: '请填您的地址', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_address"
                                            placeholder="请填您的地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </template>

                            <!---------单位工程极客---------->
                            <template
                                v-if="userInfo.info.user_type==1 && userInfo.info.attestation_type==3"
                            >
                                <div class="item">
                                    <div class="title">服务方（工程极客）</div>
                                    <el-form-item class="body">
                                        <span v-text="contract.geek_name"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <el-form-item class="body" prop="geek_is_proxy">
                                        <el-radio-group
                                            v-model="contract.geek_is_proxy"
                                            @change="geekProxyChange"
                                            size="mini"
                                        >
                                            <el-radio-button label="0">我是法人</el-radio-button>
                                            <el-radio-button label="1">我是受委托签署人</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy!=1">
                                    <div class="title">单位法定代表人</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek"
                                        :rules="[{required: true, message: '请填写法人姓名', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek"
                                            placeholder="请填写法人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy!=1">
                                    <div class="title">法人身份证号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_idcard"
                                        :rules="[{required: true, message: '请填写法人身份证号码', trigger: ['change', 'blur']},{validator:this.validata.idCard(),trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_idcard"
                                            placeholder="请填写法人身份证号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy!=1">
                                    <div class="title">法人手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请填写法人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_mobile"
                                            placeholder="请填写法人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy!=1">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请确认法人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认法人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy==1">
                                    <div class="title">受托签署人姓名</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_proxy_name"
                                        :rules="[{required: true, message: '请填写受托签署人姓名', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_proxy_name"
                                            placeholder="请填写受托签署人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy==1">
                                    <div class="title">受托签署人身份证号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_proxy_idcard"
                                        :rules="[{required: true, message: '请填写受托签署人身份证号码', trigger: ['change', 'blur']},{validator:this.validata.idCard(),trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_proxy_idcard"
                                            placeholder="请填写受托签署人身份证号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy==1">
                                    <div class="title">受托签署人手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_proxy_mobile"
                                        :rules="[{required: true, message: '请填写受托签署人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_proxy_mobile"
                                            placeholder="请填写受托签署人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy==1">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_proxy_mobile"
                                        :rules="[{required: true, message: '请确认受托签署人手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认受托签署人手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item" v-if="contract.geek_is_proxy==1">
                                    <div class="title">如您是受托签署人，请下载下面的授权委托书，签字盖章后扫描或拍照上传</div>
                                    <div class="btns" style="margin-top:10px;">
                                        <el-form-item
                                            class="body"
                                            prop="geek_proxy_certificate"
                                            :rules="[{required: true, message: '请上传授权委托书', trigger: ['change', 'blur']}]"
                                        >
                                            <el-button
                                                @click="download_geek_auth_file"
                                                size="mini"
                                            >授权委托书模板下载</el-button>
                                            <only-upload
                                                @onupload="geek_upload"
                                                :before-upload="before_upload"
                                            >
                                                <el-button slot="trigger" size="mini" v-text="upload_text"></el-button>
                                            </only-upload>
                                            <el-image
                                                class="view_proxy_pic"
                                                v-if="contract.geek_proxy_certificate"
                                                :src="util.reImg(contract.geek_proxy_certificate)"
                                            ></el-image>
                                        </el-form-item>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="title">单位联系电话</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_org_phone"
                                        :rules="[{required: true, message: '请填写单位联系电话', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_org_phone"
                                            placeholder="请填写单位联系电话"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">单位地址</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_address"
                                        :rules="[{required: true, message: '请填写单位地址', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_address"
                                            placeholder="请填写单位地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </template>

                            <!---------工作室工程极客---------->
                            <template
                                v-if="userInfo.info.user_type==1 && userInfo.info.attestation_type==2"
                            >
                                <div class="item">
                                    <div class="title">服务方（工程极客）</div>
                                    <el-form-item class="body">
                                        <span v-text="userInfo.attestation.name"></span>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">姓名</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek"
                                        :rules="[{required: true, message: '姓名', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek"
                                            placeholder="姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">身份证号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_idcard"
                                        :rules="[{required: true, message: '身份证号码', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_idcard"
                                            placeholder="请填您的地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请填写您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_mobile"
                                            placeholder="请填写您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">确认手机号码</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_mobile"
                                        :rules="[{required: true, message: '请确认您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="check_mobile"
                                            placeholder="请确认您的手机号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="item">
                                    <div class="title">地址</div>
                                    <el-form-item
                                        class="body"
                                        prop="geek_address"
                                        :rules="[{required: true, message: '请填您的地址', trigger: ['change', 'blur']}]"
                                    >
                                        <el-input
                                            v-model="contract.geek_address"
                                            placeholder="请填您的地址"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </template>

                            <div class="item">
                                <el-form-item class="body">
                                    <el-button @click="goBack">返回</el-button>
                                    <el-button @click="submit" type="primary">提交</el-button>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import onlyUpload from "@/components/upload/only-upload.vue";
export default {
    name: "taskContractEmployer",
    metaInfo() {
        return {
            title: "填写合同信息 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: { onlyUpload },
    data() {
        return {
            drawer: false, // 是否可见右侧弹出抽屉
            loading: false,
            upload_text: '上传授权委托书',
            check_mobile:'',
            contract: {
                tk: '',
                uk: '',
                employer: '',//委托方个人姓名/委托方经办人姓名
                employer_idcard: '',//委托方个人身份证号码/委托方经办人身份证号码
                employer_mobile: '',//委托方个人电话/委托方经办人电话

                employer_org_phone: '',
                employer_address: '',//委托方个人地址/委托方经办人地址

                employer_is_proxy: 0, // 委托方是否有代理人（雇主）
                employer_proxy_name: '', // 委托方代理人姓名
                employer_proxy_mobile: '', // 委托方代理人姓名
                employer_proxy_idcard: '', // 委托方代理人身份证号码
                employer_proxy_certificate: '',//授权书

                geek: '',
                geek_idcard: '',
                geek_mobile: '',

                geek_org_phone: '',
                geek_address: '',

                geek_is_proxy: 0,
                geek_proxy_name: '',
                geek_proxy_idcard: '',
                geek_proxy_mobile: '',
                geek_proxy_certificate: '',

                remark: '',
            },
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        loadData: function () {
            let _this = this;
            _this.post("/task/task/get_contract", { tk: _this.$route.query.tk, uk: _this.$route.query.uk }, res => {
                if (res.code == 200) {
                    _this.contract = res.data;
                } else {
                    _this.$message.success(res.msg);
                }
            });
        },

        //预览合同
        submit() {
            let _this = this;

            if (this.userInfo.info.user_type == 2){
                if (_this.check_mobile != _this.contract.employer_mobile && _this.check_mobile != _this.contract.employer_proxy_mobile){
                    _this.$message.success('两次手机号输入不一致');
                    return false;
                }
            }else{
                if (_this.check_mobile != _this.contract.geek_mobile && _this.check_mobile != _this.contract.geek_proxy_mobile){
                    _this.$message.success('两次手机号输入不一致');
                    return false;
                }
            }

            /**个人/工作室雇主 */
            if (this.userInfo.info.user_type == 2 && this.userInfo.info.attestation_type == 1) {
                _this.contract.employer = _this.userInfo.attestation.name;
                _this.contract.employer_idcard = _this.userInfo.attestation.idcard;
                _this.contract.employer_is_proxy = 0;
                _this.contract.employer_proxy_name = '';
                _this.contract.employer_proxy_idcard = '';
                _this.contract.employer_proxy_mobile = '';
                _this.contract.employer_proxy_certificate = '';
            }

            /**单位雇主 */
            if (this.userInfo.info.user_type == 2 && this.userInfo.info.attestation_type == 1) {
                if (_this.contract.employer_is_proxy != 1) {//无受托签署人
                    _this.contract.employer_proxy_name = '';
                    _this.contract.employer_proxy_idcard = '';
                    _this.contract.employer_proxy_mobile = '';
                    _this.contract.employer_proxy_certificate = '';
                }
            }

            /**个人/工作室工程极客 */
            if (this.userInfo.info.user_type == 1 && this.userInfo.info.attestation_type == 1) {
                _this.contract.geek = _this.userInfo.attestation.name;
                _this.contract.geek_idcard = _this.userInfo.attestation.idcard;
                _this.contract.geek_is_proxy = 0;
                _this.contract.geek_proxy_name = '';
                _this.contract.geek_proxy_idcard = '';
                _this.contract.geek_proxy_mobile = '';
                _this.contract.geek_proxy_certificate = '';
            }

            /**单位工程极客 */
            if (this.userInfo.info.user_type == 1 && this.userInfo.info.attestation_type == 1) {
                if (_this.contract.geek_is_proxy != 1) {//无受托签署人
                    _this.contract.geek_proxy_name = '';
                    _this.contract.geek_proxy_idcard = '';
                    _this.contract.geek_proxy_mobile = '';
                    _this.contract.geek_proxy_certificate = '';
                }
            }

            let form_data = {
                tk: _this.$route.query.tk,
                uk: _this.$route.query.uk,

                employer: _this.contract.employer,
                employer_idcard: _this.contract.employer_idcard,
                employer_mobile: _this.contract.employer_mobile,

                employer_is_proxy: _this.contract.employer_is_proxy,//有受托人
                employer_proxy_name: _this.contract.employer_proxy_name,//受托人姓名
                employer_proxy_idcard: _this.contract.employer_proxy_idcard,//受托人身份证号码
                employer_proxy_mobile: _this.contract.employer_proxy_mobile,//受托人手机号码
                employer_proxy_certificate: _this.contract.employer_proxy_certificate,//受托人委托书

                employer_address: _this.contract.employer_address,//单位地址
                employer_org_phone: _this.contract.employer_org_phone,//单位电话

                remark: _this.contract.remark,
            };

            if (this.userInfo.info.user_type == 1) {//极客
                form_data = {
                    tk: _this.$route.query.tk,
                    uk: _this.$route.query.uk,

                    geek: _this.contract.geek,
                    geek_idcard: _this.contract.geek_idcard,
                    geek_mobile: _this.contract.geek_mobile,

                    geek_is_proxy: _this.contract.geek_is_proxy,//有受托人
                    geek_proxy_name: _this.contract.geek_proxy_name,//受托人姓名
                    geek_proxy_idcard: _this.contract.geek_proxy_idcard,//受托人身份证号码
                    geek_proxy_mobile: _this.contract.geek_proxy_mobile,//受托人手机号码
                    geek_proxy_certificate: _this.contract.geek_proxy_certificate,//受托人委托书

                    geek_address: _this.contract.geek_address,//单位地址
                    geek_org_phone: _this.contract.geek_org_phone,//单位电话

                    remark: _this.contract.remark,
                };
            }

            _this.$refs['contract'].validate((valid, obj) => {
                if (valid) {
                    _this.loading = true;
                    let url = "/task/task/update_employer_contract";//雇主
                    if (this.userInfo.info.user_type == 1) {//极客
                        url = "/task/task/update_geek_contract";
                    }
                    _this.post(url, form_data, function (res) {
                        _this.loading = false;
                        if (res.code == 200) {
                            _this.contractFilePath = _this.websiteConfigs.sourceUrl + res.data;
                            _this.$message.success('提交成功');
                            _this.$router.push('/user/task/employer?tk=' + _this.$route.query.tk);
                        } else {
                            if (res.msg) {
                                _this.$message.success(res.msg);
                            } else {
                                _this.$message.success('提交失败');
                            }
                        }
                    });
                }
            });
        },

        //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },

        //工程极客图片上传成功
        geek_upload(val) {
            this.contract.geek_proxy_certificate = val.file;
            this.upload_text = '重新上传';
            this.local_save();
        },

        //雇主图片上传成功
        employer_upload(val) {
            this.contract.employer_proxy_certificate = val.file;
            this.upload_text = '重新上传';
            this.local_save();
        },

        //缓存表单
        local_save() {
            sessionStorage.setItem('contract_form_data', JSON.stringify(this.contract));
        },

        //选择合同签订日期事件
        selectContractDate(val = '') {
            this.local_save();
        },

        //下载合同
        download_contract() {
            this.$router.push(this.contractFilePath);
        },

        //返回
        goBack() {
            this.$router.push('/user/task/employer?tk=' + this.$route.query.tk);
        },

        //下载雇主授权书文件模板
        download_employer_auth_file() {
            window.location.href = this.websiteConfigs.sourceUrl + '/upload/contract_template/项目授权委托书_雇主.doc';
        },

        //下载工程极客授权书文件模板
        download_geek_auth_file() {
            window.location.href = this.websiteConfigs.sourceUrl + '/upload/contract_template/项目授权委托书_工程极客.doc';
        },

        //切换单位雇主法人和受委托人切换事件
        employerProxyChange(val) {
            if (val == 1) {
                this.contract.employer = '';
                this.contract.employer_idcard = '';
            } else {
                this.contract.employer_proxy_name = '';
                this.contract.employer_proxy_idcard = '';
            }
        },

        //切换单位工程极客法人和受委托人切换事件
        geekProxyChange(val) {
            if (val == 1) {
                this.contract.geek = '';
                this.contract.geek_idcard = '';
            } else {
                this.contract.geek_proxy_name = '';
                this.contract.geek_proxy_idcard = '';
            }
        }
    },
    created() {
        let _data = sessionStorage.getItem('contract_form_data');
        if (_data) {
            this.contract = JSON.parse(_data);
        } else {
            this.loadData();//加载数据
        }

        if(this.contract.employer_proxy_certificate){
            this.upload_text = '上传授权委托书';
        }

        if(this.contract.geek_proxy_certificate){
            this.upload_text = '上传授权委托书';
        }
    },
};
</script>

<style lang="less" scoped>
@import "../../../styles/task_sign.less";
@import "../../../styles/agreement.less";

.view_proxy_pic {
    width: 140px;
    clear: both;
}
</style>